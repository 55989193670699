<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')" :maxWidth="'100hv'">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? data.address : "Новый объект" }} [{{ statusCurrent.text }}]
      </template>
      <v-row>
        <v-col cols="12" sm="6">
          <v-card-title class="pt-0"> Информация об объекте </v-card-title>
          <v-row class="mt-1" :style="{ height: $root.windowSize.innerHeight - 150 - 50 + 'px' }" style="overflow-y: auto; overflow-x: hidden">
            <v-col cols="12" class="py-0 pt-1">
              <a-form-model
                :ref="'field_status_site'"
                v-model="data"
                :model="getFormModel(['status_site'], model)"
                :errors="errors"
                :config="{ dense: true, readonly: !getAccess('site.status') }"
                @validate="onChangeStatus($event)"
              />
            </v-col>
            <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
              <div v-for="name in el.name.split('+')" :key="name">
                <a-form-model
                  :ref="'field_' + name"
                  v-model="data"
                  :model="getFormModel([name], model)"
                  :errors="errors"
                  :config="{ dense: true, hideDetails: true, readonly: true }"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" :style="{ height: $root.windowSize.innerHeight - 150 + 'px' }" style="overflow-y: auto; overflow-x: hidden">
          <s-props-form
            v-if="value"
            :model="{ title: 'Дополнительные данные для сайта', id, type: objectType }"
            :id="id"
            :type="objectType"
            :config="propsConfig"
            :objectData="data"
          />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-status v-if="getAccess('site.status')" :items="statusItems" @click="clickStatus($event)" />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <confirm-dialog ref="confirmDialog" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, libChecks, genModel, statusChange } from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, libChecks, getAccess, genModel, statusChange],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    objectType: { type: String, default: "objects" },
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.site,
      suEditShow: false,
      loaded: false,
      model: [],
    };
  },
  computed: {
    url() {
      return this.api;
    },
    config() {
      return this.m.config.site;
    },

    propsConfig() {
      let res = {};
      res.readonly = !this.getAccess("site.edit");
      return res;
    },
    statuses() {
      return this.$root.config.meta.enum["status_site"];
    },
    statusItems() {
      let items = [];
      let res = [];
      let status = {};
      let type;

      type = "status_site";
      status = this.getStatus(type);
      if (status?.["next"]) {
        res = [
          ...res,
          ...this.statuses.filter((s) => {
            return status.next.includes(s.value) && s.field == type;
          }),
        ];
      }

      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let idx = this.statuses.findIndex((el) => {
        return el.value && this.data.status_site && el.field == "status_site";
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(this.statuses[idx]));
    },
  },
  watch: {
    value(v) {
      if (v) this.updateData(this.id);
    },
  },
  created() {
    this.model = this.calcModel();
    if (this.value) this.updateData(this.id);
  },
  methods: {
    async onChangeStatus(e) {
      if (this.data.status_site == 0) {
        this.data.status_site = 1;
        console.log("make ==1");
      }
      let status = { value: this.data.status_site, field: e };
      console.log("changeStatus", status);
      this.changeStatus(status);
    },
    async afterFetchData() {
      if (this.objectType == "objects")
        try {
          let resp = await this.$axios.get("/mechti/construction-reports", { params: { filters: { object_id: this.id }, sort: { key: "id", order: "DESC" } } });
          let reports = [];
          if (resp.data.data) {
            resp.data.data.forEach((r) => {
              reports = [...reports, ...r.photos];
            });
          }
          this.data.photos_report = [...reports];
        } catch (error) {}
    },
    fitchData() {},
    getStatus(type) {
      let idx = this.statuses.findIndex((el) => {
        return el.value == this.data[type] && el.field == type;
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(this.statuses[idx]));
    },
    addProps() {},
    calcModel(t) {
      //переназначить функцию, потому что немного отличается от того что в библиотеке genModel
      const config = this.config;
      let model = this.getModelList(this.m, "form", true);
      const fields = config.fields ? config.fields.split(",") : [];
      let fieldsRO = config.fieldsRO ? config.fieldsRO.split(",") : [];
      const fieldsForm = config.fieldsForm ? config.fieldsForm.split(",") : [];
      model = model.filter((el) => {
        return fields.includes(el.name);
      });
      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
      });
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split("#");
        mForm.push({ name: f[0], size: f[1] || 12 });
      });

      this.modelForm = mForm;
      this.fillFormFromModel(model);

      return model;
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>
